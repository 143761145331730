

.textPad{
    padding-top: 10px;
    padding-bottom: 10px;
}

.containerPad{
    padding-top: 50px;

}

.uibg{
    background-color: #FAFAFA;
}

.lightText{

}

.headerImage{
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: bottom
}

h3, h4, h5{
    color: #c7111c;
}

.modal-title{
    color: #c7111c;
}

.shareLink{
    color: #c7111c;
    font-size: 24px;
    margin:10px;
}