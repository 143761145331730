.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.fc-event{
cursor: pointer;
}

@media (min-width: 992px) {
  #calendar .calendar-view .day.grid {
    max-height: 600px;
  }
  #calendar .calendar-view .day.grid h5 small {
    display: none;
  }
  #calendar .calendar-view .day.grid .events-holder a {
    width: 25px;
    height: 25px;
    border-radius: 15px;
    margin-right: 2px;
  }
  #calendar .calendar-view .day.grid .events-holder .course-name {
    display: none;
  }
  #calendar .calendar-view .day.calendar-prior-months-date h5 small {
    display: none;
  }
}